import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { textColor } from '../../utils';
import * as Icon from 'react-feather';
import './style.scss';
import ReactGA from 'react-ga';

export const GuideItem = withRouter(
	({ name, id, categories, location, category, fillText, indexName, clickHandle }) => {
		const { state } = location;

		const clickGuideItemHandler = () => {
			if (clickHandle) clickHandle();
			ReactGA.event({
				category: 'Переходы по результатам поиска',
				action: 'Переход в "Гид по компании"',
				label: 'Открытия детального просмотра гида по компании',
			});
		};

		return (
			<Link
				to={{
					pathname: `/knowledge/guide/${categories}/article/${id}?fromIndex=${indexName}`,
					state: {
						...state,
						back: state && !!state.back ? state.back - 1 : -1,
					},
				}}
				className="block-item block-item_guide"
				onClick={clickGuideItemHandler}
			>
				<svg role="img" className="stroke-svg">
					<use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#search-article'} />
				</svg>
				<div className="block-item-info">
					<span>{textColor(name, fillText)}</span>
					<div className="block-item-folder">
						<Icon.Folder className="block-item-featherIcon" size="15" />
						<span>{textColor(category, fillText)}</span>
					</div>
				</div>
			</Link>
		);
	}
);
